import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { BRANDS } from "app/constants";
import { getBrandDisplayName } from "app/reducers/partnerSelector";
import uniqBy from "lodash/uniqBy";
import flattenDeep from "lodash/flattenDeep";
import { useLocation } from "react-router-dom";
import appGlobalsContext from "app/AppGlobalsContext";
import { useContext } from "react";

const getAlternates = (path, shops = [], brand) => {
	const domainsByShop = shops.map((shopData = {}) => {
		const marketingCodes = shopData.marketingCodes || [];
		const domainsByMarketingCodes = marketingCodes
			.filter(marketingCode => marketingCode.isDefaultMarketingCodeForShop === true)
			.map(marketingCode => {
				const shop = `${marketingCode.locale}-${shopData.sellingCountry}`;
				const shopBaseUrl = marketingCode?.technicalVars?.BASE_URL;
				const isGB = shop === "en-GB";
				let hreflangValue = isGB ? "en" : shop;

				if (marketingCode.locale.toUpperCase() === shopData.sellingCountry) {
					hreflangValue = marketingCode.locale;
				}
				return {
					rel: "alternate",
					hreflang: hreflangValue,
					href:
						brand === BRANDS.PS && shop !== "fr-FR"
							? `https://booking.perfectstay.com/${shop}${path}`
							: `${shopBaseUrl}/${shop}${path}`,
				};
			});

		return domainsByMarketingCodes;
	});

	return uniqBy(flattenDeep(domainsByShop), domain => domain.hreflang + domain.href);
};

const HeadMetadata = ({
	brandDisplayName,
	shops = [],
	prefetchRessources = [],
	title,
	titleAttributes = {},
	robotsMetaTag = "",
	imageUrl = "",
	description,
	descriptionAttributes = {},
	children,
}) => {
	const intl = useIntl();

	const { brand, shop, envVars } = useContext(appGlobalsContext);

	const environment = envVars.ENVIRONMENT;

	const { pathname } = useLocation();

	const computedPathname = pathname === "/" ? "" : pathname;

	const alternates = getAlternates(computedPathname, shops, brand);

	return (
		<Helmet>
			{title && (
				<title>
					{intl.formatMessage(
						{ id: title },
						{ siteTitle: brandDisplayName, ...titleAttributes }
					)}
				</title>
			)}

			{title && (
				<meta
					property="og:title"
					content={intl.formatMessage(
						{ id: title },
						{ siteTitle: brandDisplayName, ...titleAttributes }
					)}
				/>
			)}

			{description && (
				<meta
					name="description"
					content={intl.formatMessage(
						{ id: description },
						{ siteTitle: brandDisplayName, ...descriptionAttributes }
					)}
				/>
			)}

			{description && (
				<meta
					property="og:description"
					content={intl.formatMessage(
						{ id: description },
						{ siteTitle: brandDisplayName, ...descriptionAttributes }
					)}
				/>
			)}

			{imageUrl && <meta property="og:image" content={imageUrl} />}

			{imageUrl && <meta name="thumbnail" content={imageUrl} />}

			{robotsMetaTag && (
				<meta
					name="robots"
					content={environment?.includes("live") ? robotsMetaTag : "noindex, nofollow"}
				/>
			)}

			<link
				rel="canonical"
				href={
					brand === BRANDS.PS && shop !== "fr-FR"
						? `https://booking.perfectstay.com/${shop}${computedPathname}`
						: `${envVars.BASE_URL}/${shop}${computedPathname}`
				}
			/>

			{alternates.map(({ hreflang, href }, index) => {
				return <link key={index} rel="alternate" hrefLang={hreflang} href={href} />;
			})}

			{prefetchRessources.map((resourceUrl, index) => {
				return <link key={index} rel="preload" as="image" href={resourceUrl} />;
			})}

			{children}
		</Helmet>
	);
};

HeadMetadata.propTypes = {
	brandDisplayName: PropTypes.string,
	shops: PropTypes.array,
	title: PropTypes.string,
	titleAttributes: PropTypes.object,
	robotsMetaTag: PropTypes.string,
	imageUrl: PropTypes.string,
	description: PropTypes.string,
	descriptionAttributes: PropTypes.object,
	prefetchRessources: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {
	return {
		brandDisplayName: getBrandDisplayName(state),
		shops: state.brand.shops,
	};
};

export default connect(mapStateToProps)(HeadMetadata);
