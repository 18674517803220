import { memo, useContext } from "react";
import PropTypes from "prop-types";
import Product from "@brand/Product/default/Product";
import { productShape } from "app/utils/propTypes";
import MembershipContainer from "app/pages/.shared/DynamicBlocks/blocks/Membership/MembershipBlockContainer";
import "./ProductList.scss";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import appGlobalsContext from "app/AppGlobalsContext";

const ProductList = ({
	products = [],
	showSmartDPOnRootUrl,
	hideMembershipBlock = false,
	hidePriceBanner,
}) => {
	const { resolution } = useContext(appGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	return (
		<div className="product-list" data-testid="product-list">
			{products.map((product, index) => {
				return (
					<div
						key={product.id || (product.merchandising && product.merchandising.code)}
						className="product-list__item"
						data-testid={`product-${product.uri ||
							(product.merchandising && product.merchandising.code)}`}
					>
						<Product
							isMobile={isMobile}
							product={product}
							position={index}
							hidePriceBanner={hidePriceBanner}
							showSmartDPOnRootUrl={showSmartDPOnRootUrl}
						/>
					</div>
				);
			})}
			{!hideMembershipBlock && (
				<div className="product-list__membership">
					<MembershipContainer />
				</div>
			)}
		</div>
	);
};

ProductList.propTypes = {
	products: PropTypes.arrayOf(productShape),
	showSmartDPOnRootUrl: PropTypes.bool,
	hidePriceBanner: PropTypes.bool,
	hideMembershipBlock: PropTypes.bool,
};

export default memo(ProductList);
